import React, { useEffect } from 'react'
import Layout from './Layout'

const Careers = () => {

  const GoToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    GoToTop()
  }, [])

  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <section className="text-gray-600 body-font">
          <div className='flex justify-center items-center'>
            <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
            <h1 className='text-[5vw] text-gray-600'>Careers</h1>
            <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
          </div>
          <div className="container px-5 py-16 mx-auto">
            <div className="text-center mb-20">
              <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto ">We believe that our people are the foundation of our business, and we foster an environment that encourages career growth, idea generation, and unwavering commitment to our customers' needs.</p>
            </div>
            <div className="text-center">
              <p className="font-medium text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto mt-6">Certain key requirements to join our team:</p>
            </div>
            <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-[#ee3427] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">Prospective candidates should demonstrate a drive for revenue generation and adaptability in utilizing emerging technologies.</span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-[#ee3427] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">Fluency in the English language is an essential prerequisite.</span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-[#ee3427] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">It is preferred for candidates to possess a keen numerical aptitude, as strong quantitative skills provide an additional advantage.</span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-[#ee3427] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">Familiarity with customs rules and regulations is essential.</span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-[#ee3427] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">Candidates must possess the ability to cultivate collaborative customer relationships, displaying a strong service-oriented mindset.</span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-[#ee3427] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">Strong analytical skills are highly appreciated.</span>
                </div>
              </div>
              <div className="p-2 sm:w-1/2 w-full">
                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-[#ee3427] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                  <span className="title-font font-medium">The ability to effectively multitask and coordinate is desirable.</span>
                </div>
              </div>
            </div>
            <div className="text-center mt-20">
              <p className="text-base font-medium leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                Send Your CV at :
              </p>
              <a href="mailto:mumbai@transcendmobility.com" className="text-[#ee3427] leading-relaxed">
                mumbai@transcendmobility.com
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Careers