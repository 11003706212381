import React, { useEffect } from 'react'
import Layout from './Layout'
import EnvPolicy from "../images/env_policy.jpg"

const EnvironmentPolicy = () => {

  const GoToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    GoToTop()
  }, [])

  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <section className="text-gray-600 body-font">
          <div className='flex justify-center items-center'>
            <hr className='w-[20vw] mr-[5%] border-2 border-[#ee3427]' />
            <h1 className='text-[3.72vw] text-gray-600'>Environment Policy</h1>
            <hr className='w-[20vw] ml-[5%] font-black border-2 border-[#ee3427]' />
          </div>
          <div className="container px-5 py-16 mx-auto flex flex-col">
            <div className="lg:w-4/5 mx-auto">
              <div className="rounded-lg h-1/4 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={EnvPolicy} />
              </div>
              <div className="flex flex-col sm:flex-row mt-10">
                <p className="leading-relaxed text-lg mb-4 text-center ">
                  At Transcend Mobility, we actively employ and promote the use of recycled materials, particularly for local moves. We take pride in openly communicating with our customers about the importance of recycling while also providing them with significant cost benefits.
                  Our top management is deeply committed to supporting wildlife conservation efforts in India through various charitable initiatives and logistical support. As an organization, we are currently involved in financial assistance and donation programs aimed at helping the needy in Melghat and neighboring areas. Our goal is to encourage local communities to protect the interests of the surrounding forests and wildlife. We firmly believe that growth should surpass boundaries, but not at the expense of environmental degradation.
                  If you would like to learn more about our programs or contribute to our cause, please feel free to contact us directly at <a className="text-[#ee3427] leading-relaxed" href="mailto:mumbai@transcendmobility.org">mumbai@transcendmobility.org</a>.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default EnvironmentPolicy