import React, { useEffect } from 'react'
import Layout from './Layout';
import Img10 from "../images/img10.jpg";
// Import Framer-Motion React Components
import { motion } from "framer-motion"
import { NavLink } from 'react-router-dom';

const FineArtMoving = () => {

    const GoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        GoToTop()
    }, [])

    return (
        <Layout>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <section className="text-gray-600 body-font relative">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Services</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <section className="text-gray-600 body-font">
                        <div className="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
                            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                                <img className="object-cover object-center rounded" alt="hero" src={Img10} />
                            </div>
                            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                                <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                                    Fine Art Moving
                                </h1>
                                <p className="mb-8 leading-relaxed text-justify">
                                    Our specialized division for fine arts offers a comprehensive range of services meticulously designed for seamless integration. These services encompass the packing, transportation, and storage of high-risk and delicate items, including paintings, fragile ceramics, antiques, sculptures, and invaluable artifacts.
                                    <br />
                                    Rest assured that your fine art pieces will be handled and safeguarded by our skilled professionals, who utilize tailor-made packing equipment and materials. Throughout the transportation process, we prioritize the safety and security of your precious artifacts. Our experienced handlers employ custom crates specifically designed for each artwork and its unique journey. These crates are crafted from fumigated wood and provide exceptional protection against shocks and vibrations. The interior lining ensures prevention of friction damage, while the sealed construction shields the artworks from various climate changes, ensuring their utmost preservation.
                                </p>
                            </div>
                        </div>
                    </section>
                    <div className='w-full flex justify-center items-center'>
                        <NavLink to='/freequote' className="mr-5  cursor-pointer">
                            <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="inline-flex items-center bg-[#ee3427] text-white border-0 py-1 px-3 focus:outline-none hover:bg-red-700 rounded text-base mt-4 md:mt-0">
                                Contact Us
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </motion.button>
                        </NavLink>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default FineArtMoving