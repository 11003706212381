import React, { useEffect } from 'react'
import Layout from './Layout';
import { useState } from 'react'
// Import Framer-Motion React Components
import { motion } from "framer-motion";
import emailjs from '@emailjs/browser';

import { Navigate } from 'react-router-dom';

const FreeQuote = () => {

    const GoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        GoToTop()
    }, [])

    const [open, setOpen] = React.useState(true);
    const [values, setValues] = useState({
        "first-name": "",
        "last-name": "",
        "moving-from": "",
        "moving-to": "",
        "email": "",
        "phone": "",
        "message": "",
    })

    const handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        setValues((prevValue) => {
            return { ...prevValue, [name]: value }
        })
    }

    const handleSubmit = async () => {
        try {
            if (values['first-name'] != "" || values['first-name'] != "" || values['moving-from'] != "" || values['moving-to'] != "" || values.email != "" || values.phone != "" || values.message != "") {
                const templateParams = {
                    name: values['first-name'] + " " + values['last-name'],
                    movingFrom: values['moving-from'],
                    movingTo: values['moving-to'],
                    email: values.email,
                    phone: values.phone,
                    message: values.message,
                };
                emailjs.send('service_ad5gice', 'template_63n4mbs', templateParams, 'psMD0P56PQ_pt2xYt')
                    .then((response) => {
                        console.log('Email sent successfully!', response);
                        <Navigate to='/home' replace={true} />
                    })
                    .catch((error) => {
                        console.error('Failed to send email:', error);
                    });
            } else {
                throw new Error('Empty Field');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Layout>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <section className="text-gray-600 body-font relative">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Free Quote</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <div className="isolate bg-white px-6 sm:pb-32 lg:px-8">
                        <form action="" method="" className="mx-auto max-w-xl sm:mt-20">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                <div>
                                    <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                        First name
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            onChange={handleChange}
                                            value={values['first-name']}
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="first-name"
                                            className="w-full bg-white rounded border border-gray-300 focus:border-[#ee3427] focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Last name
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            onChange={handleChange}
                                            value={values['last-name']}
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autoComplete="last-name"
                                            className="w-full bg-white rounded border border-gray-300 focus:border-[#ee3427] focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="moving-from" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Moving From
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            onChange={handleChange}
                                            value={values['moving-from']}
                                            type="text"
                                            name="moving-from"
                                            id="moving-from"
                                            autoComplete="moving-from"
                                            className="w-full bg-white rounded border border-gray-300 focus:border-[#ee3427] focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="moving-to" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Moving To
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            onChange={handleChange}
                                            value={values['moving-to']}
                                            type="text"
                                            name="moving-to"
                                            id="moving-to"
                                            autoComplete="moving-to"
                                            className="w-full bg-white rounded border border-gray-300 focus:border-[#ee3427] focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            onChange={handleChange}
                                            value={values.email}
                                            type="email"
                                            name="email"
                                            id="email"
                                            autoComplete="email"
                                            className="w-full bg-white rounded border border-gray-300 focus:border-[#ee3427] focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="phone" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Phone Number
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            onChange={handleChange}
                                            value={values.phone}
                                            type="phone"
                                            name="phone"
                                            id="phone"
                                            autoComplete="phone"
                                            className="w-full bg-white rounded border border-gray-300 focus:border-[#ee3427] focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Message
                                    </label>
                                    <div className="mt-2.5">
                                        <textarea
                                            onChange={handleChange}
                                            value={values.message}
                                            name="message"
                                            id="message"
                                            rows={4}
                                            className="w-full bg-white rounded border border-gray-300 focus:border-[#ee3427] focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10">
                                <motion.button
                                    whileTap={{ scale: 0.8 }}
                                    whileHover={{ scale: 1.1 }}
                                    onClick={handleSubmit}
                                    type="button"
                                    className="block w-full rounded-md bg-[#ee3427] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Send
                                </motion.button>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default FreeQuote