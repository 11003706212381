import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import {
  Home,
  AboutUs,
  Careers,
  ContactUs,
  EnvironmentPolicy,
  FreeQuote,
  InternationalAndDomesticMoving,
  PetRelocation,
  FineArtMoving,
  OfficeIndustrialMoving,
  Storage,
  NotFound
} from "./components"

const App = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Navigate to='/home' />} />
        <Route path='/home' element={<Home />} />
        <Route path='/services/internationalanddomesticmoving' element={<InternationalAndDomesticMoving />} />
        <Route path='/services/petrelocation' element={<PetRelocation />} />
        <Route path='/services/fineartmoving' element={<FineArtMoving />} />
        <Route path='/services/officeindustrialmoving' element={<OfficeIndustrialMoving />} />
        <Route path='/services/storage' element={<Storage />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/environmentpolicy' element={<EnvironmentPolicy />} />
        <Route path='/freequote' element={<FreeQuote />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App