import React from 'react'
import { Fragment, useEffect } from 'react'
import logo from '../logo.png';
import "../App.css";
// Import Framer-Motion React Components
import { motion } from "framer-motion"
import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const Layout = ({ children }) => {

    const GoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        GoToTop()
    },[])

    const MovingToArr = [
        'Australia',
        'Belgium',
        'Canada',
        'China',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Indonesia',
        'Malaysia',
        'Mexico',
        'North Korea',
        'Norway',
        'Qatar',
        'Russia',
        'Saudi Arabia',
        'Singapore',
        'South Korea',
        'Switzerland',
        'Thailand',
        'UAE',
        'UK',
        'USA',
        'Vietnam',
    ]
    const MovingToArr1 = [
        'Australia',
        'Belgium',
        'Canada',
        'China',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Indonesia',
        'Malaysia',
        'Mexico',
        'North Korea',
    ]
    const MovingToArr2 = [
        'Norway',
        'Qatar',
        'Russia',
        'Saudi Arabia',
        'Singapore',
        'South Korea',
        'Switzerland',
        'Thailand',
        'UAE',
        'UK',
        'USA',
        'Vietnam',
    ]
    return (
        <>
            <header className="text-white sticky top-0 z-[2000] bg-white body-font">
                <div className="border-b-2 border-gray-300 container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <NavLink to='/home' className="flex title-font font-medium items-center text-white mb-0 md:mb-0">
                        <img src={logo} alt="logo" className="w-[14rem] h-[4rem] " />
                    </NavLink>
                    <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
                        <NavLink to='/home' className="mr-5 text-[18px] hover:text-[#ee3427] text-black cursor-pointer">Home</NavLink>
                        <NavLink to='/aboutus' className="mr-5 text-[18px] hover:text-[#ee3427] text-black cursor-pointer">About Us</NavLink>
                        <Menu as="div" className="relative inline-block text-left mr-5">
                            <Menu.Button className="hover:text-[#ee3427] text-[18px] text-black flex justify-center items-center w-full ">
                                Services
                                <ChevronDownIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-[1000] mt-2 w-56 origin-top-right rounded-md bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            <NavLink
                                                className='hover:text-[#ee3427]  block px-4 py-2 text-sm'
                                                to='/services/internationalanddomesticmoving'>
                                                International and Domestic Moving
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <NavLink
                                                className='hover:text-[#ee3427]  text-white block px-4 py-2 text-sm'
                                                to='/services/petrelocation'>
                                                Pet Relocation
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <NavLink
                                                className='hover:text-[#ee3427]  text-white block px-4 py-2 text-sm'
                                                to='/services/storage'>
                                                Storage
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <NavLink
                                                className='hover:text-[#ee3427]  text-white block px-4 py-2 text-sm'
                                                to='/services/fineartmoving'>
                                                Fine art Moving
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <NavLink
                                                className='hover:text-[#ee3427]  text-white block px-4 py-2 text-sm'
                                                to='/services/officeindustrialmoving'>
                                                Office/Industrial Moving
                                            </NavLink>
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                        <Menu as="div" className="relative inline-block text-left mr-5 ">
                            <Menu.Button className="hover:text-[#ee3427] text-[18px] text-black flex justify-center items-center w-full ">
                                Moving To
                                <ChevronDownIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="overflow-auto absolute right-0 z-[1000] h-[40vh] mt-2 w-56 origin-top-right rounded-md bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        {
                                            MovingToArr.map((e) => {
                                                return (
                                                    <Menu.Item>
                                                        <NavLink
                                                            className='hover:text-[#ee3427] text-white block px-4 py-2 text-sm'
                                                            to='/freequote'>
                                                            {e}
                                                        </NavLink>
                                                    </Menu.Item>
                                                )
                                            })
                                        }
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                        <NavLink to='/careers' className="mr-5 hover:text-[#ee3427] text-[18px] text-black cursor-pointer">Careers</NavLink>
                        <NavLink to='/environmentpolicy' className="mr-5 hover:text-[#ee3427] text-[18px] text-black cursor-pointer">Environment Policy</NavLink>
                        <NavLink to='/contactus' className="mr-5 hover:text-[#ee3427] text-[18px] text-black cursor-pointer">Contact Us</NavLink>
                    </nav>
                    <NavLink to='/freequote' className="mr-5 cursor-pointer">
                        <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="inline-flex text-[18px] items-center bg-[#ee3427] border-0 py-1 px-3 focus:outline-none hover:bg-red-700 rounded text-base mt-4 md:mt-0">Free Quote
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </motion.button>
                    </NavLink>
                </div>
            </header>
            <main>
                {children}
            </main>
            <footer className="text-gray-400 bg-gray-700 body-font">
                <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                        <a className="flex flex-col title-font font-medium items-center md:justify-start justify-center text-white">
                            <img src={logo} alt="logo" className="w-60 h-30 " />
                        </a>
                    </div>
                    <div className="flex-grow w-[90vw] flex flex-wrap justify-center -mb-10 md:mt-0 mt-10 md:text-left text-center">
                        <div className=" w-full px-4">
                            <h2 className="title-font text-xl font-medium text-white tracking-widest text-md mb-3 flex justify-center items-center">AREAS WE SERVE</h2>
                            <div className="flex justify-center items-center w-full">
                                <nav className="list-none mb-10 mr-4">
                                    {
                                        MovingToArr1.map((e) => {
                                            return (
                                                <li>
                                                    <NavLink to='/freequote' className="text-white cursor-pointer hover:text-[#ee3427]">
                                                        Moving to {e}
                                                    </NavLink>
                                                </li>
                                            )
                                        })
                                    }
                                </nav>
                                <nav className="list-none mb-10">
                                    {
                                        MovingToArr2.map((e) => {
                                            return (
                                                <li>
                                                    <NavLink to='/freequote' className="text-white cursor-pointer hover:text-[#ee3427]">
                                                        Moving to {e}
                                                    </NavLink>
                                                </li>
                                            )
                                        })
                                    }
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow flex flex-wrap -mb-10 md:mt-0 mt-10 md:text-left text-center">
                        <div className="lg:w-1/2 md:w-1/2 w-full px-4">
                            <h2 className="title-font text-xl font-medium text-white tracking-widest text-md mb-3">SERVICES</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <NavLink to='/services/internationalanddomesticmoving' className="text-white cursor-pointer hover:text-[#ee3427]">International and Domestic Moving</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/services/petrelocation' className="text-white cursor-pointer hover:text-[#ee3427]">Pet Relocation</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/services/storage' className="text-white cursor-pointer hover:text-[#ee3427]">Storage</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/services/officeindustrialmoving' className="text-white cursor-pointer hover:text-[#ee3427]">Office/Industrial Moving</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/services/fineartmoving' className="text-white cursor-pointer hover:text-[#ee3427]">Fine Arts Moving</NavLink>

                                </li>
                            </nav>
                        </div>
                        <div className="lg:w-1/2 md:w-1/2 w-full px-4">
                            <h2 className="title-font text-xl font-medium text-white tracking-widest text-md mb-3">QUICK LINKS</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <NavLink to='/aboutus' className="text-white cursor-pointer hover:text-[#ee3427]">About Us</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/careers' className="text-white cursor-pointer hover:text-[#ee3427]">Careers</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/environmentpolicy' className="text-white cursor-pointer hover:text-[#ee3427]">Environment Policy</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/contactus' className="text-white cursor-pointer hover:text-[#ee3427]">Contact Us</NavLink>
                                </li>
                            </nav>
                        </div>
                        <div className="lg:w-1/2 md:w-1/2 w-full px-4">
                            <h2 className="title-font text-xl font-medium text-white tracking-widest text-md mb-3">ADDRESS</h2>
                            <nav className="list-none mb-10 text-white ">
                                DBS Center, The Corporate Park, 8th Floor, Plot No-14/15 Sector-18, Vashi, Navi Mumbai, Maharashtra 400703
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-800 bg-opacity-75">
                    <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                        <p className="text-gray-400 text-sm text-center sm:text-left">© 2023 Transcend Mobility —
                        </p>
                        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start cursor-pointer">
                            <a href='https://www.linkedin.com/company/transcend-mobility/' about='' className="ml-3 text-gray-400">
                                <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                                    <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                    <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                </svg>
                            </a>
                        </span>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Layout