import React, { useEffect } from 'react'
import Layout from './Layout';
import ServiceInt from "../images/serviceInternational.png"
import ServiceDomestic from "../images/serviceDomestic.jpg";
// Import Framer-Motion React Components
import { motion } from "framer-motion"
import { NavLink } from 'react-router-dom';



const InternationalAndDomesticMoving = () => {

    const GoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        GoToTop()
    }, [])

    return (
        <Layout>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <section className="text-gray-600 body-font relative">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Services</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <section className="text-gray-600 body-font">
                        <div className="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
                            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                                <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                                    International Moving
                                </h1>
                                <p className="mb-8 leading-relaxed text-justify">
                                    Moving internationally is a significant decision that shouldn't be made hastily. That is why, we offer complete solutions for people, couples, families, and corporate offices going overseas. To guarantee that all personal possessions or business equipment is carried safely and securely, we coordinate a variety of top-notch moving services.
                                    <br />
                                    New places, new people, new culture awaits you when you relocate to another country. Truly exciting but the intimidating part of moving is the specialized handling of your valued personal household items especially if it's your first time to move to another country.
                                    <br />
                                    As a relocations company offering services across the Globe, Transcend Mobility knows how to deal with your personal valuables and belongings. If you so require, we take full responsibility for the complete relocation planning and execution.
                                    <br />
                                    Packing as an activity in the International Move is something we do best to none. Our Packing Material is well researched where in our own team members have been involved in researching and creating an end product that is robust and extremely environment friendly. We clearly see exponential results in our commitment to offer the best Packing Quality in the Moving Services Sphere.
                                </p>
                            </div>
                            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                <img className="object-cover object-center rounded" alt="hero" src={ServiceInt} />
                            </div>
                        </div>
                    </section>
                    <section className="text-gray-600 body-font">
                        <div className="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
                            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                                <img className="object-cover object-center rounded" alt="hero" src={ServiceDomestic} />
                            </div>
                            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                                <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                                    Domestic Moving
                                </h1>
                                <p className="mb-8 leading-relaxed text-justify">
                                    Are you tired of the same excuses given by a Moving Company in India? In that case, enquire with us. We are transcend Mobility, a name that resonates with quality and cost effective ways to move your belongings whether within your city or within India. Transportation in India is today still in many ways fairly dis-organized. Transcend Mobility is making all efforts along with its professional partners in bringing transparent and organized ways of Moving your belongings. Having its own and associate offices in Major Cities of India, Transcend Mobility is ensuring its Domestic Customers top quality service.
                                    <br />
                                    Our team is built on years of experience and a sharp understanding of the business of movement, we are now known to provide high-quality moving services to corporate, families and individuals.
                                </p>
                            </div>
                        </div>
                    </section>
                    <div className='w-full flex justify-center items-center'>
                        <NavLink to='/freequote' className="mr-5  cursor-pointer">
                            <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="inline-flex items-center bg-[#ee3427] text-white border-0 py-1 px-3 focus:outline-none hover:bg-red-700 rounded text-base mt-4 md:mt-0">
                                Contact Us
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </motion.button>
                        </NavLink>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default InternationalAndDomesticMoving