import React, { useEffect } from 'react'
import Layout from './Layout'
import Img5 from '../images/img5.png';
import AboutBottom from "../images/aboutusbottom.png"

const AboutUs = () => {

    const GoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        GoToTop()
    }, [])

    return (
        <Layout>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <section className="text-gray-600 body-font pt-8">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>About Us</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <div className="container mx-auto flex px-5 py-10 md:flex-row flex-col items-center">
                        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                            <img className="object-cover object-center rounded" alt="hero" src={Img5} />
                        </div>
                        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                            <p className="mb-8 leading-relaxed text-justify">
                                Welcome to Transcend Mobility, a dynamic and forward-thinking organization that began its journey
                                as a Proprietary Firm in 2018. From the very beginning, we partnered with prestigious institutions
                                such as BNP Paribas Bank, Naval Group, and the American School of Bombay, solidifying our
                                reputation as a trusted and reliable service provider. In 2019, we underwent a transformative
                                evolution, becoming a Private Limited company while expanding our portfolio to include Leading
                                International Moving Services. This expansion allowed us to join the esteemed IAM Global Network,
                                a testament to our commitment to excellence and global reach.
                                <br />
                                Our dedication to deliver exceptional services has earned us noteworthy recognition. We proudly
                                represented our company at the esteemed IAM 2019 Convention in Chicago, USA, and continued to
                                showcase our expertise at the IAM 2022 Convention in Atlanta, USA. At Transcend, we thrive on
                                providing impeccable solutions tailored to the unique needs of our esteemed clientele. With a focus
                                on professionalism and a passion for excellence, we are ready to exceed your expectations in every
                                aspect of our services. Partner with us and experience the difference that sets us apart in the
                                industry.
                            </p>
                        </div>
                    </div>
                    <section className="text-gray-600 body-font">
                        <div className="text-center mb-20">
                            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Our Mission</h1>
                            <p className="text-base leading-relaxed mx-auto text-gray-500s text-justify">
                                Our mission is to help our clients achieve their relocation goals. We understand that moving
                                can be a stressful experience, and we are committed to making the process as smooth and easy as
                                possible for our clients. We will provide our clients with the information and resources they need to
                                make informed decisions about their relocation, and we will work with them every step of the way to
                                ensure that their move is successful.
                            </p>
                        </div>
                        <div className="container flex flex-wrap px-5 pb-16 mx-auto justify-center items-center">
                            <div className="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-[#ee3427]">
                                <h1 className="sm:text-3xl flex justify-center items-center text-2xl font-medium title-font mb-2 text-gray-900">Our Vision</h1>
                                <p className="leading-relaxed text-base text-justify">
                                    To be a globally recognized brand in the Relocations industry, with a Unique leadership
                                    position in the Indian market.

                                </p>
                            </div>
                            <div className="flex flex-col md:w-1/2 md:pl-12">
                                <h1 className="sm:text-3xl text-2xl font-medium flex justify-center items-center title-font mb-2 text-gray-900">Our Values</h1>
                                <p className="leading-relaxed text-base text-justify">
                                    Our team embraces integrity, excellence, innovation, and teamwork, all with a steadfast commitment to client focus.
                                </p>
                            </div>
                        </div>
                    </section>
                </section>
                <section className="text-gray-600 body-font">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Mentors Word</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <div className="container px-5 py-24 mx-auto">
                        <div className="flex flex-wrap -m-4">
                            <div className="p-4 w-full">
                                <div className="h-full bg-gray-100 p-8 rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-[#ee3427] mb-4" viewBox="0 0 975.036 975.036">
                                        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                                    </svg>
                                    <p className="leading-relaxed mb-6 text-justify">
                                        A Technology Graduate with MBA in International business, Vineet brings unparalleled Experience of Moving over 10,000 People in his Career that spans 18 years plus with Stints with Largest Relocation Companies. He has been Part of Global International Conventions, including but not limited to, IAM San Diego 2015, IAM Chicago 2019 and IAM Atlanta 2022.
                                        Being well traveled Globally, he understands the cultural nuisances that are critical to empathize with, in the Relocations and Mobility Space. He takes special Interest in Pet Relocations.
                                        When Not Moving people, Vineet prefers to spend time with his Daughter. He is an Avid Wildlife Enthusiast and has contributed extensively to “Save Wildlife” Campaigns in Melghat and Tadoba Tiger Reserve. He also happens to be an exceptional Theatre Artist and has acted in over several English, Hindi and Marathi plays.
                                    </p>
                                    <a className="inline-flex items-center">
                                        <span className="flex-grow flex flex-col pl-4">
                                            <span className="title-font font-medium text-gray-900">Vineet Walimbe </span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap -m-4">
                            <div className="p-4 md:w-1/2 w-full">
                                <div className="h-full bg-gray-100 p-8 rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-[#ee3427] mb-4" viewBox="0 0 975.036 975.036">
                                        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                                    </svg>
                                    <p className="leading-relaxed mb-6 text-justify">
                                        Mr. Walimbe has been credited with creating, shaping and sustaining best Financial and Accounting practices for one of India’s Largest Freight forwarding Companies. He Spent 30 plus Years in one organization which speaks volumes about his unmatched Dedication and loyalty. He brings expertise Cost Management, Financial planning and Analytics.
                                        Mr. G D Walimbe is a fitness freak. At 71, he swims and sprints like a 21 Year old.  He is also a Hindustani Classical Singer and a strict Disciplinarian.
                                    </p>
                                    <a className="inline-flex items-center">
                                        <span className="flex-grow flex flex-col pl-4">
                                            <span className="title-font font-medium text-gray-900">G D Walimbe </span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className="p-4 md:w-1/2 w-full">
                                <div className="h-full bg-gray-100 p-8 rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-[#ee3427] mb-4" viewBox="0 0 975.036 975.036">
                                        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                                    </svg>
                                    <p className="leading-relaxed mb-6 text-justify">
                                        Tarun Aggarwal is an experienced Growth Enabler with a 17-year marketing career across various industries. He specializes in driving profitability, crafting brand narratives, and generating business growth. His expertise includes brand management, innovative campaign strategies, digital marketing, and customer lifecycle management. Currently in a Marketing Advisory role at a Global Consultancy, he previously led marketing functions at LIXIL Group, Dainik Jagran, Times Group, The Indian Express Group, and Videocon Ltd. Listed among the 'top 100 most influential marketing leaders' in 2018.
                                    </p>
                                    <a className="inline-flex items-center">
                                        <span className="flex-grow flex flex-col pl-4">
                                            <span className="title-font font-medium text-gray-900">Tarun Aggarwal</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="md:p-2 w-[20vw]">
                    <img alt="gallery" class="w-full object-cover h-full object-center block" src={AboutBottom} />
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs