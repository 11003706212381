import React, { useEffect } from 'react'
import Layout from './Layout';
import Img11 from "../images/img11.png";
// Import Framer-Motion React Components
import { motion } from "framer-motion"
import { NavLink } from 'react-router-dom';

const PetRelocation = () => {

    const GoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        GoToTop()
    },[])

    return (
        <Layout>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <section className="text-gray-600 body-font relative">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Services</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <section className="text-gray-600 body-font">
                        <div className="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
                            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                                <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                                    Pet Relocation
                                </h1>
                                <p className="mb-8 leading-relaxed text-justify">
                                    While many moving companies worldwide hesitate to handle pet relocation, Transcend Mobility embraces it with utmost pride. Our team, boasting over 12 years of experience in transporting pets to and from India across the globe, ensures a stress-free process. The initial step revolves around comprehensively understanding your pet's relocation requirements. By providing vital information such as the origin and destination, desired move date, pet species, breed, and age, we can establish clear expectations and communicate how we can facilitate your pet's move.
                                </p>
                            </div>
                            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                <img className="object-cover object-center rounded" alt="hero" src={Img11} />
                            </div>
                        </div>
                    </section>
                    <div className='w-full flex justify-center items-center'>
                        <NavLink to='/freequote' className="mr-5  cursor-pointer">
                            <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="inline-flex items-center bg-[#ee3427] text-white border-0 py-1 px-3 focus:outline-none hover:bg-red-700 rounded text-base mt-4 md:mt-0">
                                Contact Us
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </motion.button>
                        </NavLink>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default PetRelocation