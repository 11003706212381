import React, { useEffect } from 'react'
import Layout from './Layout';
import Img8 from "../images/img8.jpg";
// Import Framer-Motion React Components
import { motion } from "framer-motion"
import { NavLink } from 'react-router-dom';

const OfficeIndustrialMoving = () => {

    const GoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        GoToTop()
    },[])

    return (
        <Layout>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <section className="text-gray-600 body-font relative">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Services</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <section className="text-gray-600 body-font">
                        <div className="container mx-auto flex px-5 pt-16 md:flex-row flex-col items-center">
                            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                                <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                                    Office/Industrial Moving
                                </h1>
                                <p className="mb-8 leading-relaxed text-justify">
                                    Industrial packaging sets itself apart from other forms of packaging by providing enhanced protection for potential extended storage. Emphasis is placed on the thickness and strength of the materials used, as well as the implementation of secure closure and locking mechanisms. Priority is given to preserving machines during prolonged periods of storage or transit, ensuring they remain sealed and shielded from external contaminants.
                                    <br />
                                    At Transcend Mobility, we have embraced the use of VCI technology (Volatile Corrosion Inhibitor) to establish ourselves as leaders in this particular service sector. VCI packaging employs materials, typically paper or plastic, that have been impregnated with corrosion inhibitors. Our innovative approach enables us to maximize the potential of VCI technology, ensuring optimal protection for your machinery. Whether you are relocating a single machine, multiple machines, or planning the removal and installation of an entire production line, our team will seamlessly provide packaging solutions tailored to your needs.
                                    <br />
                                </p>
                            </div>
                            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                <img className="object-cover object-center rounded" alt="hero" src={Img8} />
                            </div>
                        </div>
                        <div className="container mx-auto pb-16 flex px-5 md:flex-row flex-col items-center">
                            <p className="text-base leading-relaxed mx-auto text-justify">
                                Crates and Palletization play a crucial role in our services. We design and construct custom industrial packaging solutions such as boxes, pallets, and crates that effectively safeguard your contents during transportation and warehousing. Our experienced carpenters utilize heat-treated wood, ensuring our crates, boxes, and pallets are built to withstand the demanding conditions of industrial packaging applications. We offer heavy machine packaging cases of the highest quality, specifically crafted for packaging, storing, and transporting heavy mechanical handling equipment. These cases feature superior construction and innovative design, allowing for secure product storage while facilitating airflow around the entire item.
                                <br />
                                If you are seeking a comprehensive packaging and logistics solution for your sophisticated and heavy equipment, Transcend Mobility stands out as an excellent choice.
                            </p>
                        </div>
                    </section>
                    <div className='w-full flex justify-center items-center'>
                        <NavLink to='/freequote' className="mr-5  cursor-pointer">
                            <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="inline-flex items-center bg-[#ee3427] text-white border-0 py-1 px-3 focus:outline-none hover:bg-red-700 rounded text-base mt-4 md:mt-0">
                                Contact Us
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </motion.button>
                        </NavLink>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default OfficeIndustrialMoving
