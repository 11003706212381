import React, { useEffect } from 'react'
import Layout from './Layout';
import ServiceStorage from '../images/serviceStorage.png';
// Import Framer-Motion React Components
import { motion } from "framer-motion"
import { NavLink } from 'react-router-dom';

const Storage = () => {

    const GoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        GoToTop()
    }, [])

    return (
        <Layout>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <section className="text-gray-600 body-font relative">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Services</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <section className="text-gray-600 body-font">
                        <div className="container mx-auto flex px-5 pt-16 md:flex-row flex-col items-center">
                            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                                <img className="object-cover object-center rounded" alt="hero" src={ServiceStorage} />
                            </div>
                            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                                <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                                    Storage
                                </h1>
                                <p className="mb-8 leading-relaxed text-justify">
                                    Our warehouses are equipped with cutting-edge technology, ensuring optimal security, safety, and protection. With round-the-clock surveillance, advanced electronic systems, and fire safety protocols in place, we prioritize the well-being of your stored items. Additionally, we conduct regular pest controls, fumigation of lift-vans, and uphold high standards of cleanliness and housekeeping within our facilities.
                                    <br />
                                    Our warehouses revolutionize storage practices within the relocations industry by implementing efficient inventory management systems. Whether it's short-term or long-term storage needs, our facilities in India readily accommodate items belonging to embassies/consulates, corporate museums, dignitaries, and countless families.
                                    <br />
                                    We understand that unforeseen circumstances may require temporary or extended storage solutions. Whether you're embarking on a quick international transfer or waiting for a suitable location, Transcend Mobility's storage services ensure the safekeeping of your belongings, allowing you to focus on your endeavors.
                                </p>
                            </div>
                        </div>
                        <div className="container mx-auto pb-16 flex px-5 md:flex-row flex-col items-center">
                            <p className="text-base ml-0 leading-relaxed mx-auto">
                                Transcend Mobility storage facility offers:
                                <br />
                                <span className="bg-gray-200 text-[#ee3427] w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>Comprehensive CCTV surveillance.
                                <br />
                                <span className="bg-gray-200 text-[#ee3427] w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>24-hour manned security.
                                <br />
                                <span className="bg-gray-200 text-[#ee3427] w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>Environment with controlled humidity levels.
                                <br />
                                <span className="bg-gray-200 text-[#ee3427] w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>Containerized storage options for both short-term and long-term needs.
                                <br />
                                <span className="bg-gray-200 text-[#ee3427] w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>Abundant stock of packing supplies.
                                <br />
                                <span className="bg-gray-200 text-[#ee3427] w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>Availability of wooden lift-vans for both short-term and long-term storage.
                                <br />
                                <span className="bg-gray-200 text-[#ee3427] w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>Limited availability of temperature-controlled storage for specific items.
                            </p>
                        </div>
                    </section>
                    <div className='w-full flex justify-center items-center'>
                        <NavLink to='/freequote' className="mr-5  cursor-pointer">
                            <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="inline-flex items-center bg-[#ee3427] text-white border-0 py-1 px-3 focus:outline-none hover:bg-red-700 rounded text-base mt-4 md:mt-0">
                                Contact Us
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </motion.button>
                        </NavLink>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default Storage