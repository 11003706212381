import React, { useEffect } from 'react'
import Layout from './Layout'
import "../App.css"
import Img1 from "../images/img1.jpg"
import Img2 from "../images/img2.jpg"
import Img3 from "../images/img3.jpg"
import Img4 from "../images/img4.jpg"
import Img5 from "../images/img5.png"
import Img8 from "../images/img8.jpg"
import Img10 from "../images/img10.jpg"
import Img11 from "../images/img11.png"
import Img13 from "../images/img13.jpg"
import ClientLogo1 from "../images/clientLogo1.png";
import ClientLogo2 from "../images/clientLogo2.png";
import ClientLogo3 from "../images/clientLogo3.png";
import ClientLogo4 from "../images/clientLogo4.png";
import ClientLogo5 from "../images/clientLogo5.png";
import ClientLogo6 from "../images/clientLogo6.png";
import ClientLogo7 from "../images/clientLogo7.png";
import ClientLogo8 from "../images/clientLogo8.png";
import ClientLogo9 from "../images/clientLogo9.png";
import ClientLogo10 from "../images/clientLogo10.png";
import ClientLogo11 from "../images/clientLogo11.png";
import ClientLogo12 from "../images/clientLogo12.png";
import ServiceStorage from '../images/serviceStorage.png';
import ServiceInt from "../images/serviceInternational.png"
import Process from "../images/Process-1.png";
import TagLine from "../images/tag_line.png";

import logo from '../logo.png';

// Import Framer-Motion React Components
import { motion } from "framer-motion"

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import { NavLink } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { EffectFade, Autoplay, Pagination } from 'swiper/modules';


const Home = () => {

    const GoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        GoToTop()
    }, [])
    
    return (
        <Layout>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className='relative truncate'>
                <Swiper
                    effect={'fade'}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[EffectFade, Autoplay]}
                    className="mySwiper h-auto"
                >
                    <SwiperSlide >
                        <img src={Img1} />
                    </SwiperSlide>
                    <SwiperSlide >
                        <img src={Img2} />
                    </SwiperSlide>
                    <SwiperSlide >
                        <img src={Img3} />
                    </SwiperSlide>
                    <SwiperSlide >
                        <img src={Img4} />
                    </SwiperSlide>
                    <SwiperSlide >
                        <img src={Img13} />
                    </SwiperSlide>
                </Swiper>
                <div className="discription absolute bg-blend-overlay bg-white">
                    <div className='w-full h-full flex justify-center items-center flex-col '>
                        <img src={TagLine} alt="tagline" className="p-0 m-0 " border="0" />
                    </div>
                </div>
            </motion.div>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                {/* About Us Section  */}
                <section className="text-gray-600 body-font pt-8">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>About Us</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <div className="container mx-auto flex px-5 py-10 md:flex-row flex-col items-center">
                        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                            <img className="object-cover object-center rounded" alt="hero" src={Img5} />
                        </div>
                        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                            <p className="mb-8 leading-relaxed text-justify">
                                Welcome to Transcend Mobility, a dynamic and forward-thinking organization that began its journey
                                as a Proprietary Firm in 2018. From the very beginning, we partnered with prestigious institutions
                                such as BNP Paribas Bank, Naval Group, and the American School of Bombay, solidifying our
                                reputation as a trusted and reliable service provider. In 2019, we underwent a transformative
                                evolution, becoming a Private Limited company while expanding our portfolio to include Leading
                                International Moving Services. This expansion allowed us to join the esteemed IAM Global Network,
                                a testament to our commitment to excellence and global reach.
                            </p>
                            <div className="flex justify-center">
                                <NavLink to='/aboutus'>
                                    <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="inline-flex items-center text-white bg-[#ee3427] border-0 py-2 px-6 focus:outline-none hover:bg-red-700 rounded text-lg">
                                        Know More
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </motion.button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Our Services Section  */}
                <section className="text-gray-600 body-font">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Our Services</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <div className="container px-5 py-24 mx-auto">
                        <div className="flex flex-wrap -mx-4 -mb-10 text-center">
                            <div className="sm:w-1/2 mb-10 px-4">
                                <div className="rounded-lg h-64 overflow-hidden">
                                    <img alt="content" className="object-cover object-center h-full w-full" src={ServiceInt} />
                                </div>
                                <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">International and Domestic Moving</h2>
                                <p className="leading-relaxed text-base">Moving internationally is a significant decision that shouldn't be made hastily. That is why, we offer complete solutions for people, couples, families, and corporate offices going overseas</p>
                                <NavLink to='/services/internationalanddomesticmoving'>
                                    <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="flex mx-auto mt-6 text-white bg-[#ee3427] border-0 py-2 px-5 focus:outline-none hover:bg-red-700 rounded">
                                        Read More
                                    </motion.button>
                                </NavLink>
                            </div>
                            <div className="sm:w-1/2 mb-10 px-4">
                                <div className="rounded-lg h-64 overflow-hidden">
                                    <img alt="content" className="object-cover object-center h-full w-full" src={Img11} />
                                </div>
                                <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">Pet Relocation</h2>
                                <p className="leading-relaxed text-base">While many moving companies worldwide hesitate to handle pet relocation,Transcend Mobility embraces it with utmost pride.Our team,boasting over 12 years of experience in transporting pets to and from India.</p>
                                <NavLink to='/services/petrelocation'>
                                    <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="flex mx-auto mt-6 text-white bg-[#ee3427] border-0 py-2 px-5 focus:outline-none hover:bg-red-700 rounded">
                                        Read More
                                    </motion.button>
                                </NavLink>
                            </div>
                            <div className="sm:w-1/2 mb-10 px-4">
                                <div className="rounded-lg h-64 overflow-hidden">
                                    <img alt="content" className="object-cover object-center h-full w-full" src={Img8} />
                                </div>
                                <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">Office/Industrial Moving</h2>
                                <p className="leading-relaxed text-base">Industrial packaging sets itself apart from other forms of packaging by providing enhanced protection for potential extended storage. Emphasis is placed on the thickness and strength of the materials used, as well as the implementation.</p>
                                <NavLink to='/services/officeindustrialmoving'>
                                    <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="flex mx-auto mt-6 text-white bg-[#ee3427] border-0 py-2 px-5 focus:outline-none hover:bg-red-700 rounded">
                                        Read More
                                    </motion.button>
                                </NavLink>
                            </div>
                            <div className="sm:w-1/2 mb-10 px-4">
                                <div className="rounded-lg h-64 overflow-hidden">
                                    <img alt="content" className="object-cover object-center h-full w-full" src={ServiceStorage} />
                                </div>
                                <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">Storage </h2>
                                <p className="leading-relaxed text-base">Our warehouses are equipped with cutting-edge technology, ensuring optimal security, safety, and protection. With round-the-clock surveillance, advanced electronic systems, and fire safety protocols in place. </p>
                                <NavLink to='/services/storage'>
                                    <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="flex mx-auto mt-6 text-white bg-[#ee3427] border-0 py-2 px-5 focus:outline-none hover:bg-red-700 rounded">
                                        Read More
                                    </motion.button>
                                </NavLink>
                            </div>
                            <div className="sm:w-1/2 mb-10 px-4">
                                <div className="rounded-lg h-64 overflow-hidden">
                                    <img alt="content" className="object-cover object-center h-full w-full" src={Img10} />
                                </div>
                                <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">Fine Arts Moving</h2>
                                <p className="leading-relaxed text-base">Our specialized division for fine arts offers a comprehensive range of services meticulously designed for seamless integration.</p>
                                <NavLink to='/services/fineartmoving'>
                                    <motion.button whileTap={{ scale: 0.8 }} whileHover={{ scale: 1.2 }} className="flex mx-auto mt-6 text-white bg-[#ee3427] border-0 py-2 px-5 focus:outline-none hover:bg-red-700 rounded">
                                        Read More
                                    </motion.button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Our Process Section  */}
                <section className="text-gray-600 body-font">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[4.3vw] text-gray-600'> Stress-Free Move</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <div className="container px-5 py-24 mx-auto">
                        <img src={Process} alt="Process" />
                    </div>
                </section>
                {/* Our Testimonials Section  */}
                <section className="text-gray-600 body-font">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Testimonials</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <Swiper
                        style={{ "--swiper-pagination-color": "#ee3427" }}
                        pagination={true}
                        modules={[Pagination, Autoplay]}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        className="mySwiper">
                        <SwiperSlide>
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="inline-block w-8 h-8 text-[#ee3427] mb-8" viewBox="0 0 975.036 975.036">
                                        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                                    </svg>
                                    <p className="leading-relaxed text-lg">Transcend Mobility provided outstanding relocation service from Malaysia to Gurgaon, India. It was my best moving experience yet. From the first call to the final delivery, everything was seamless, professional, and damage-free. The movers were punctual, careful, and efficient in packing and loading. The delivery was on time, and they assisted with unpacking and furniture assembly. I highly recommend Transcend Mobility for their attention to detail and excellent customer service.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-[#ee3427] mt-8 mb-6"></span>
                                    <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">MR. AG</h2>
                                    <p className="text-gray-500"></p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="inline-block w-8 h-8 text-[#ee3427] mb-8" viewBox="0 0 975.036 975.036">
                                        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                                    </svg>
                                    <p className="leading-relaxed text-lg">Thanks for your assistance with our move from US to Mumbai. Moving countries is complex, requiring more than packing boxes. A reliable relocation agent who understands our needs, communicates well, and is patient is very crucial. Mr. Vineet and his team made our move smooth. We appreciate Transcend Mobility’s service and highly recommend his team for moves.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-[#ee3427]  mt-8 mb-6"></span>
                                    <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">MS. MR</h2>
                                    <p className="text-gray-500"></p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="inline-block w-8 h-8 text-[#ee3427] mb-8" viewBox="0 0 975.036 975.036">
                                        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                                    </svg>
                                    <p className="leading-relaxed text-lg">Thank you for your excellent pet relocation services from Brazil to India. I had a terrible experience with your competitor, but in a single call, you clarified my concerns and guided me in the right direction. Throughout the challenging process, you maintained close communication, ensuring the successful move of my beloved dog. My family and I are immensely grateful, as our little dog is a cherished part of our family. Best regards from all of us.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-[#ee3427]  mt-8 mb-6"></span>
                                    <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">MR. TO</h2>
                                    <p className="text-gray-500"></p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="inline-block w-8 h-8 text-[#ee3427] mb-8" viewBox="0 0 975.036 975.036">
                                        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                                    </svg>
                                    <p className="leading-relaxed text-lg">We were happy with your team’s work. They came well prepared and did packing /unpacking with care and followed the instructions carefully.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-[#ee3427]  mt-8 mb-6"></span>
                                    <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">MR. GM</h2>
                                    <p className="text-gray-500"></p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </section>
                {/* Our Clients Section  */}
                <section className="bg-white body-font">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Our Clients</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
                        <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 dark:text-gray-400">
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo1} alt="ClientLogo1" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo2} alt="ClientLogo2" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo3} alt="ClientLogo3" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo4} alt="ClientLogo4" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo5} alt="ClientLogo5" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo6} alt="ClientLogo6" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo7} alt="ClientLogo7" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo8} alt="ClientLogo8" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo9} alt="ClientLogo9" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo10} alt="ClientLogo9" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo11} alt="ClientLogo9" />
                            </a>
                            <a href="#" className="flex justify-center items-center">
                                <img src={ClientLogo12} alt="ClientLogo9" />
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default Home