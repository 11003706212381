import React, { useState, useEffect } from 'react';
import Layout from './Layout'
import "../App.css";
// Import Framer-Motion React Components
import { motion } from "framer-motion"
import emailjs from '@emailjs/browser';
import { Navigate } from 'react-router-dom';

const ContactUs = () => {

    const GoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        GoToTop()
    },[])

    const [values, setValues] = useState({
        "name": "",
        "email": "",
        "message": "",
    })

    const handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        setValues((prevValue) => {
            return { ...prevValue, [name]: value }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (values.email != "" || values.name != "" || values.message != "") {
                const templateParams = {
                    name: values.name,
                    email: values.email,
                    message: values.message,
                };
                emailjs.send('service_ad5gice', 'template_9x3lq08', templateParams, 'psMD0P56PQ_pt2xYt')
                    .then((response) => {
                        console.log('Email sent successfully!', response);
                        <Navigate to='/home' replace={true} />
                    })
                    .catch((error) => {
                        console.error('Failed to send email:', error);
                    });
            } else {
                throw new Error('Empty Field');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Layout>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <section className="text-gray-600 body-font relative">
                    <div className='flex justify-center items-center'>
                        <hr className='w-[20vw] mr-[4%] border-2 border-[#ee3427]' />
                        <h1 className='text-[5vw] text-gray-600'>Contact Us</h1>
                        <hr className='w-[20vw] ml-[4%] font-black border-2 border-[#ee3427]' />
                    </div>
                    <div className='flex items-center justify-around flex-wrap my-8'>
                        <div className="max-w-sm h-52 p-6 bg-white border border-gray-200 rounded-lg shadow m-2">
                            <i className=" fa-solid fa-location-dot" style={{ "color": "#ee3427" }}></i>
                            <h5 className="mb-2 text-2xl font-semibold tracking-tight">Transcend Mobility, Mumbai</h5>
                            <p className="mb-3 font-normal">DBS Center, The Corporate Park, 8th Floor, Plot No-14/15 Sector -18, Vashi, Navi Mumbai, Maharashtra 400703</p>
                        </div>
                        <div className="max-w-sm h-52 p-6 bg-white border border-gray-200 rounded-lg shadow m-2">
                            <i className="fa-solid fa-location-dot" style={{ "color": "#ee3427" }}></i>
                            <h5 className="mb-2 text-2xl font-semibold tracking-tight">Warehouse Address, Mumbai</h5>
                            <p className="mb-3 font-normal">The Silver Springs warehousing complex, Taloja MIDC, Navi Mumbai 410210</p>
                        </div>
                        <div className="max-w-sm h-52 p-6 bg-white border border-gray-200 rounded-lg shadow m-2">
                            <i className="fa-solid fa-location-dot" style={{ "color": "#ee3427" }}></i>
                            <h5 className="mb-2 text-2xl font-semibold tracking-tight">Transcend Mobility, New Delhi/Gurgaon</h5>
                            <p className="mb-3 font-normal">Tyagi Ware Housing Complex, Chattarpur,New Delhi-110074</p>
                        </div>
                        <div className="max-w-sm h-52 p-6 bg-white border border-gray-200 rounded-lg shadow m-2">
                            <i className="fa-solid fa-location-dot" style={{ "color": "#ee3427" }}></i>
                            <h5 className="mb-2 text-2xl font-semibold tracking-tight">Transcend Mobility, Pune</h5>
                            <p className="mb-3 font-normal">Tower 57, Ground Floor, 3, The Amanora Future Towers, 411028</p>
                        </div>
                        <div className="max-w-sm h-52 p-6 bg-white border border-gray-200 rounded-lg shadow m-2">
                            <i className="fa-solid fa-location-dot" style={{ "color": "#ee3427" }}></i>
                            <h5 className="mb-2 text-2xl font-semibold tracking-tight">Transcend Mobility, Chennai</h5>
                            <p className="mb-3 font-normal">DBS House 31A, Cathedral Garden Road,Chennai 600034</p>
                        </div>
                        <div className="max-w-sm h-52 p-6 bg-white border border-gray-200 rounded-lg shadow m-2">
                            <i className="fa-solid fa-location-dot" style={{ "color": "#ee3427" }}></i>
                            <h5 className="mb-2 text-2xl font-semibold tracking-tight">Transcend Mobility, Bangalore</h5>
                            <p className="mb-3 font-normal">KR Defense Colony, Virgonagar, Cheemasandra, Bengaluru, Karnataka 560049</p>
                        </div>
                    </div>
                    <div className="container px-5 py-16 mx-auto flex sm:flex-nowrap flex-wrap">
                        <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                            <iframe className=" absolute inset-0" frameBorder="0" title="map" marginHeight="0" marginWidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30167.12852277738!2d72.98361355163733!3d19.06852656885178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c14676ca8e09%3A0xf0470f64507b558e!2sDBS%20Business%20Center!5e0!3m2!1sen!2sus!4v1689750619083!5m2!1sen!2sus" width="100%" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                                <div className="lg:w-1/2 px-6">
                                    <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                                    <p className="mt-1">DBS Center, The Corporate Park, 8th Floor, Plot No-14/15 Sector-18, Vashi, Navi Mumbai, Maharashtra 400703</p>
                                </div>
                                <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                                    <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                                    <a href="mailto:enquiries@transcendmobility.org" className="text-[#ee3427] leading-relaxed">enquiries@transcendmobility.org</a>
                                    <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                                    <a className="leading-relaxed" href="tel:+919152015437">+91 9152015437</a>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Get In Touch With Us</h2>
                            <div className="relative mb-4">
                                <label htmlFor="name" className="leading-7 text-sm text-gray-600">Name</label>
                                <input onChange={handleChange} value={values.name} type="text" id="name" name="name" className="w-full bg-white rounded border border-gray-300 focus:border-[#ee3427] focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                            <div className="relative mb-4">
                                <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                                <input onChange={handleChange} value={values.email} type="email" id="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-[#ee3427] focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                            <div className="relative mb-4">
                                <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
                                <textarea onChange={handleChange} value={values.message} id="message" name="message" className="w-full bg-white rounded border border-gray-300 focus:border-[#ee3427] focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                            </div>
                            <motion.button
                                whileTap={{ scale: 0.8 }}
                                whileHover={{ scale: 1.1 }}
                                onClick={handleSubmit}
                                className="text-white bg-[#ee3427] border-0 py-2 px-6 focus:outline-none hover:bg-red-700 rounded text-lg">Send</motion.button>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default ContactUs